<template>
	<v-container fluid>
		<ResetPasswordForm v-if="token" color="transparent" :dark="false" :token="token" />

		<RequestPasswordReset v-else color="transparent" :dark="false" />
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t('auth.resetPassword')
		}
	},
	data() {
		return {
			token: null
		}
	},
	components: {
		ResetPasswordForm: () => import('@/components/auth/ResetPasswordForm.vue'),
		RequestPasswordReset: () => import('@/components/auth/RequestPasswordReset.vue')
	},
	created() {
		if (this.$route.query.token) {
			this.token = this.$route.query.token
		}
	}
}
</script>
